<template>
  <div class="program">
    <div class="program-header">
      <div class="header-left">
        <el-button icon="el-icon-arrow-left" size="small" @click="$router.back()">返回</el-button>
        <div class="header-left-name">{{ info.name }}</div>
      </div>
      <div class="header-middle">
        <el-radio-group v-model="remoteMethod" @change="remoteChange">
          <el-radio-button label="云桌面" value="云桌面" />
          <el-radio-button label="jupyter" value="jupyter" />
        </el-radio-group>
      </div>
      <div class="header-right">
        <span v-if="experimentUserEndTime">请注意: 本次在线实验将在 {{ experimentUserEndTime }} 自动清除,
          请合理安排时间!</span>
        <span v-else-if="info">请注意: 本次在线实验限时 {{ info.timelong_style }}
        </span>
        <!-- <el-button icon="el-icon-tickets" size="small" @click="drawerShow = true">试题列表</el-button> -->
      </div>
    </div>
    <div class="program-main">
      <div class="left" ref="left">
        <!--        <div style="position: absolute;width: 100%;height: 100%;left: 0;top: 0"></div>-->
        <!--        <embed style="position: relative;z-index: 2" v-if="pdfFile?.source?.path"  id="iframe" ref="myIframe" :src="pdfFile?.source?.path" width="100%" height="100%" type="application/pdf" />-->
        <ExperimentSource v-if="sourceGroup.length" :sourceGroup="sourceGroup"></ExperimentSource>
        <div v-else style="text-align: center; font-size: 14px">暂无数据</div>
      </div>
      <div class="line" ref="line" @mousedown="md">
        <i class="el-icon-more"></i>
      </div>
      <div class="right" ref="right">
        <div class="right-cont" id="cloud-screen" ref="cloudScreenRef"
          element-loading-background="rgba(255, 255, 255, 1)"
          v-show="experimentUserInfo?.status == '1' && remoteMethod === '云桌面'"></div>
        <div class="mask" v-loading="true" v-if="vncStatus" element-loading-text="请稍后"></div>
        <div class="right-cont" v-show="experimentUserInfo?.status == '1' && remoteMethod === 'jupyter'">
          <iframe :src="jupyterUrl" allowfullscreen frameborder="0" style="width: 100%;height: 100%;"></iframe>
        </div>
        <div class="right-pre" v-show="experimentUserInfo?.status != '1'">
          <el-button type="primary" @click="openExperiment()">创建实验</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RFB from "@novnc/novnc/core/rfb";
import { experDockerWss, experDockerApi } from "@/router/init";
import util from "@/utils/tools.js";
import ExperimentSource from "./components/experimentSource/index.vue";
export default {
  name: "ProgramView",
  data() {
    return {
      position: {
        x: 0,
        move: false,
      },
      // 实验id
      experimentId: "",
      experimentUserInfo: {},
      experimentUserEndTime: "",
      dockerUrl: "",
      jupyterUrl: "",
      rfb: null,
      pdfFile: {},
      info: {},
      sourceGroup: [],
      closeRfb: false,
      timer: null,
      remoteMethod: "云桌面",
      vncLoading: false,
      vncStatus: false,
      vncLoadingTimer: null
    };
  },
  components: {
    ExperimentSource,
  },
  created() {
    this.experimentId = this.$route.query.id || "";
    this.getDetail();
    this.getExperimentUser(this.openExperiment);
    window.addEventListener("mousemove", this.mv);
    window.addEventListener("mouseup", this.mp);
    window.addEventListener("paste", this.clipboardPasteFrom);
  },
  beforeDestroy() {
    this.closeRfb = true;
    this.rfb && this.rfb.disconnect();
    clearTimeout(this.vncLoadingTimer);
    window.removeEventListener("mousemove", this.mv);
    window.removeEventListener("mouseup", this.mp);
    window.removeEventListener("paste", this.clipboardPasteFrom);
  },
  methods: {
    clipboardPasteFrom() {
      navigator.clipboard.readText().then(text => {
        this.rfb && this.rfb.clipboardPasteFrom(text);
      });
    },
    remoteChange() {
      if (this.remoteMethod === "云桌面") {
        this.$refs.cloudScreenRef.innerHTML = "";
        this.$nextTick(() => {
          this.getExperimentUser(this.connectVnc);
        });
      }
    },
    // 拖动中间线条改变两侧大小
    md(e) {
      const { position } = this;
      position.x = e?.target?.offsetLeft || 0;
      position.move = true;
    },
    mv(e) {
      const { position } = this;
      if (!position.move) return;
      position.x = e.pageX;
      this.$refs.left.style.width = `${e.pageX}px`;
      this.$refs.right.style.width = "0px";
    },
    mp() {
      const { position } = this;
      position.move = false;
    },
    getDetail() {
      let params = {
        id: this.experimentId,
      };
      this.api.dataset
        .experimentDetail(params)
        .then((res) => {
          this.info = res.info;
          this.sourceGroup = res.source_group;
          let arr = this.info?.resource.filter((item) => {
            return item.source_type === "3";
          });
          this.pdfFile = arr.length ? arr[0] : {};
          console.warn("获取实验详情成功");
        })
        .catch((err) => {
          console.error("获取实验详情失败", err);
        });
    },
    getExperimentUser(callback) {
      let params = {
        experiment_id: this.experimentId,
      };
      this.vncLoading = true;
      this.api.dataset
        .experimentUser(params)
        .then((res) => {
          this.experimentUserInfo = res.info;
          if (this.experimentUserInfo?.status == "1") {
            callback && callback();
          }
          this.vncLoading = false;
          // if (callback == 1){
          //   if(this.experimentUserInfo?.status == '1'){
          //     this.openExperiment();
          //   }else{
          //     console.error("this.experimentUserInfo.status",this.experimentUserInfo?.status);
          //   }
          // }else if (callback == 2){
          //   if (this.experimentUserInfo?.status == '1'){
          //     console.log("websocket断线重连");
          //     this.connectVnc();
          //   }else{
          //     console.error("this.experimentUserInfo.status",this.experimentUserInfo?.status);
          //   }
          // }
        })
        .catch(() => {
          this.vncLoading = false;
        });
    },
    openExperiment() {
      let params = {
        experiment_id: this.experimentId,
      };
      this.vncLoading = true;
      this.vncStatus = true;
      this.api.dataset
        .dockerOpen(params)
        .then((res) => {
          this.experimentUserInfo = res.experiment_user;
          this.experimentUserEndTime = util.timeFormatter(
            new Date(+this.experimentUserInfo.end_time * 1000),
            "yyyy-MM-dd hh:mm"
          );
          this.dockerUrl = this.getDockerUrl(this.experimentUserInfo.docker_address);
          this.jupyterUrl = this.getJupyterUrl(this.experimentUserInfo.jupyter_address);
          this.connectVnc();

          this.vncLoading = false;
        })
        .catch((err) => {
          console.error("打开实验失败", err);
          this.vncLoading = false;
        });
    },
    getDockerUrl(hostport) {
      // let protocol = '';
      // if (window.location.protocol === 'https:') {
      //   protocol = 'wss://';
      // } else {
      //   protocol = 'ws://';
      // }
      // 加window.location.host可以走vue.config.js的代理，ws://localhost:8081/vnc/192.168.18.57:5900
      //const wsUrl = `${protocol}${window.location.host}/vnc/${hostport}`;
      //const wsUrl = `wss://dev.experdockerapi.saas.edu360.cn/${hostport}`;
      const wsUrl = experDockerWss + `/${hostport}`;
      console.log('getDockerUrl', wsUrl);
      return wsUrl;
    },
    getJupyterUrl(hostport) {
      // let protocol = '';
      // if (window.location.protocol === 'https:') {
      //   protocol = 'wss://';
      // } else {
      //   protocol = 'ws://';
      // }
      // 加window.location.host可以走vue.config.js的代理，ws://localhost:8081/vnc/192.168.18.57:5900
      //const wsUrl = `${protocol}${window.location.host}/vnc/${hostport}`;
      //const wsUrl = `wss://dev.experdockerapi.saas.edu360.cn/${hostport}`;
      const Url = experDockerApi + `${hostport}`;
      console.log('getJupyterUrl', Url);
      return Url;
    },
    // vnc连接断开的回调函数
    disconnectedFromServer(msg) {
      console.error("服务器->断开连接1", msg);
      if (this.closeRfb) return;
      console.error("服务器->断开连接2", msg);
      // clean是boolean指示终止是否干净。在发生意外终止或错误时 clean将设置为 false。
      if (msg.detail.clean) {
        // 根据 断开信息的msg.detail.clean 来判断是否可以重新连接
        console.log("连接不可用（clean）");
        this.connectVnc();
      } else {
        // 这里做不可重新连接的一些操作
        console.log("连接不可用（可能需要密码）");
        this.rfb = null;
        //延迟1秒重连
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          console.log("websocket断线重连");
          //查询实验状态, 若实验是进行中则重连
          this.getExperimentUser(this.connectVnc);
          clearTimeout(this.timer);
        }, 1000);
      }
    },
    // 连接成功的回调函数
    connectedToServer() {
      this.vncLoading = false;
      console.log("服务器->连接成功");
    },
    //连接vnc的函数
    connectVnc() {
      console.log("连接开始");
      const PASSWORD = "";
      this.$nextTick(() => {
        let rfb = new RFB(document.getElementById("cloud-screen"), this.dockerUrl, {
          // 向vnc 传递的一些参数，比如说虚拟机的开机密码等
          credentials: { password: PASSWORD },
        });
        console.log("建立连接");
        rfb.addEventListener("connect", this.connectedToServer);
        rfb.addEventListener("disconnect", this.disconnectedFromServer);
        // scaleViewport指示是否应在本地扩展远程会话以使其适合其容器。禁用时，如果远程会话小于其容器，则它将居中，或者根据clipViewport它是否更大来处理。默认情况下禁用。
        rfb.scaleViewport = true;
        // 是一个boolean指示是否每当容器改变尺寸应被发送到调整远程会话的请求。默认情况下禁用
        rfb.resizeSession = true;
        //处理粘贴复制
        //rfb.clipboardPasteFrom(text);
        this.rfb = rfb;
      });
    },
  },
  watch: {
    vncLoading(newV) {
      if (!newV) {
        clearTimeout(this.vncLoadingTimer);
        this.vncLoadingTimer = setTimeout(() => {
          this.vncStatus = false;
        }, 3000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.program {
  height: 100vh;
  overflow: hidden;
  background-color: white;

  .program-header {
    height: 60px;
    box-sizing: border-box;
    background-color: white;
    border-bottom: 10px solid #f7f8f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .header-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .header-left-name {
        margin: 0 20px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .program-main {
    height: calc(100% - 70px);
    display: flex;
    overflow: auto;

    .left {
      width: 20%;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      position: relative;

      .title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .details {
        display: flex;
        color: #666;
        font-size: 16px;
        margin-bottom: 20px;

        .details-item {
          margin-right: 10px;
        }
      }

      //.knowledge-points {
      //  display: flex;
      //  align-items: center;
      //  margin-bottom: 20px;
      //
      //  .knowledge-points-title {
      //    font-size: 16px;
      //    font-weight: bold;
      //    color: #333;
      //  }
      //
      //  .knowledge-points-item {
      //    padding: 2px 5px;
      //    border-radius: 20px;
      //    color: #666;
      //    margin-left: 10px;
      //    background-color: #f7f8f9;
      //    border: 1px solid #e3e3e6
      //  }
      //}

      .describe {
        margin-bottom: 20px;

        .describe-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }

        .describe-desc {
          font-size: 16px;
        }
      }

      //.input {
      //  margin-bottom: 20px;
      //
      //  .input-title {
      //    font-size: 18px;
      //    color: #333;
      //    font-weight: bold;
      //    margin-bottom: 5px;
      //  }
      //
      //  .input-content {
      //    background-color: #f7f8f9;
      //    border-radius: 4px;
      //    padding: 20px;
      //    font-size: 16px;
      //  }
      //}

      //.output {
      //  margin-bottom: 20px;
      //
      //  .output-title {
      //    font-size: 18px;
      //    color: #333;
      //    font-weight: bold;
      //    margin-bottom: 5px;
      //  }
      //
      //  .output-content {
      //    background-color: #f7f8f9;
      //    border-radius: 4px;
      //    padding: 20px;
      //    font-size: 16px;
      //  }
      //}
    }

    .line {
      width: 10px;
      transition: 0.3s;
      background-color: #f7f8f9;
      cursor: move;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #686868;

      i {
        transform: rotate(90deg);
      }

      &:hover {
        background-color: #dfdfdf;
      }
    }

    .right {
      flex-grow: 1;
      width: 70%;
      display: flex;
      flex-direction: column;
      position: relative;

      .mask {
        position: absolute;
        background-color: white;
        left: 0;
        height: 0;
        width: 100%;
        height: 100%;
      }

      .right-cont {
        width: 100%;
        height: 100%;
        background-color: #282828;
      }

      .right-pre {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
